body {
  margin: 0 !important;
  background: #f2f1ef !important;
  font-family: Outfit !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.MuiOutlinedInput-input {
  padding: 15px !important;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  height: 5px;
}

@media screen and (max-width: 1242px) {
  .removeScrollBar::-webkit-scrollbar {
    display: none;
  }

  .removeScrollBar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

@media screen and (max-width: 600px) {
  body {
    background: #fff !important;
  }
}
